import React from 'react';
import { useTranslations } from 'next-intl';
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import Image from 'next/image';
import { MapPin } from 'lucide-react';
import Link from 'next/link';
type MarkerProps = {
  coords: {
    lat: number;
    lng: number;
  };
  data?: {
    title: string;
    address: string;
    coverImage: string;
    sports: string[];
    slug: string;
  };
  isSelected: boolean;
  setSelectedMarker: () => void;
};
export const MarkerWithInfowindow = ({
  coords,
  data,
  isSelected,
  setSelectedMarker
}: MarkerProps) => {
  const t = useTranslations('map_marker');
  const sportsT = useTranslations('sports');
  const [markerRef, marker] = useAdvancedMarkerRef();
  return <>
      <AdvancedMarker ref={markerRef} onClick={() => setSelectedMarker()} onMouseEnter={() => setSelectedMarker()} position={coords} title={t('title')} data-sentry-element="AdvancedMarker" data-sentry-source-file="Marker.tsx" />
      {isSelected && data && <InfoWindow anchor={marker} maxWidth={275} headerDisabled className="p-0 overflow-hidden pt-0 pr-0" style={{
      paddingTop: 0,
      paddingRight: 0
    }}>
          <Link href={`/clubs/${data.slug}`}>
            <Card className="w-full max-h-[268px] overflow-hidden border-none p-0 overflow-y-hidden">
              <div className="relative h-[120px] w-full">
                <Image src={data.coverImage} alt={`${data.title} cover image`} fill className="object-cover" priority />
              </div>
              <CardHeader className="p-2">
                <CardTitle className="text-base">{data.title}</CardTitle>
              </CardHeader>
              <CardContent className="grid gap-2 p-2 pt-0">
                <div className="text-xs">
                  <span className="font-bold">{t('sports')}</span>{' '}
                  {data.sports.map(sport => sportsT(sport)).join(', ')}
                </div>
                <div className="flex items-centertext-xs text-muted-foreground text-xs">
                  <span className="font-bold mr-1">
                    {t('title')}
                    {':'}
                  </span>
                  <span>{data.address}</span>
                </div>
              </CardContent>
            </Card>
          </Link>
        </InfoWindow>}
    </>;
};