'use client';

import { APIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps';
import { MarkerWithInfowindow } from './Marker';
import { useState } from 'react';
import './styles.css';
export interface MarkerProps {
  coords: {
    lat: number;
    lng: number;
  };
  data?: {
    title: string;
    sports: string[];
    address: string;
    coverImage: string;
    slug: string;
  };
  id: string;
}
export default function Map({
  className,
  markers
}: {
  className?: string;
  markers: MarkerProps[];
}) {
  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);

  //47.0105° N, 28.8638° E Chisinau
  const defaultCenter = markers.length === 1 ? markers[0].coords : {
    lat: 47.0105,
    lng: 28.8638
  };
  return <APIProvider apiKey={'AIzaSyCz1neBqtDejwtWIIoGWl4Kw0EiTera1bU'} libraries={['marker']} data-sentry-element="APIProvider" data-sentry-component="Map" data-sentry-source-file="Map.tsx">
      <GoogleMap className={className} mapId={'bf51a910020fa25a'} style={{
      width: '100%',
      height: '100%',
      borderRadius: '4px',
      overflow: 'hidden'
    }} defaultCenter={defaultCenter} defaultZoom={12} gestureHandling={'auto'} disableDefaultUI={true} id="map" onClick={() => selectedMarker && setSelectedMarker(null)} data-sentry-element="GoogleMap" data-sentry-source-file="Map.tsx">
        {markers.map(marker => <MarkerWithInfowindow key={marker.id} isSelected={selectedMarker === marker.id} setSelectedMarker={() => setSelectedMarker(marker.id)} data={marker.data} coords={marker.coords} />)}
      </GoogleMap>
    </APIProvider>;
}